window.mes_app = window.mes_app or {}
((guardians, $) ->
  $.fn.selectpicker.Constructor.BootstrapVersion = '4';
  guardians.load_selectpicker = ->
    if($(window).width()>=1024)
      $('select').selectpicker("refresh");

  guardians.load_projectchanged = ->
    $(document).off "change","#guardian_project_id"
    $(document).on "change","#guardian_project_id", ->
      url="/guardians/load_projectchanged"
      $.ajax
        url: url
        dataType: "script"
        type: "GET"
        data:
          project_id: $(@).val()

  guardians.init_roleselect = ->
    url="/guardians/load_projectchanged"
    $.ajax
      url: url
      dataType: "script"
      type: "GET"
      data:
        project_id: $("#guardian_project_id").val()

  guardians.showGuardians = (id) ->
    x = document.getElementById(id + "Collapse")
    if x.style.display is "none"
      x.style.display = "block"
      document.cookie = "project_#{id}_status=block"
    else
      x.style.display = "none"
      document.cookie = "project_#{id}_status=none"

  guardians.getCookie = (name) ->
    value = "; #{document.cookie}"
    parts = value.split("; #{name}=")
    if parts.length is 2
      parts.pop().split(';').shift()

  guardians.applyGuardiansStatusFromCookies = () ->
    document.querySelectorAll(".card-title.link").forEach (element) =>
      projectId = element.getAttribute("onclick").match(/\d+/)[0]
      status = guardians.getCookie("project_#{projectId}_status")
      if status
        x = document.getElementById("#{projectId}Collapse")
        x.style.display = status

) window.mes_app.guardians = window.mes_app.guardians or {}, jQuery
