import React from 'react'
import ReactDOM from 'react-dom'
import Command from './Command.jsx'

class MainCommandPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      cmds: []
    };
  }

  componentDidMount() {
    fetch(this.props.fetchInitialDataURL)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            cmds: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    return <div className="card index">
        <div className="card-header card-header-primary">
          <h1 className="card-title">All Slack Commands</h1>
        </div>
        <div className="card-body">{
            this.state.cmds.map(cmd => (<Command isLoggedIn={this.props.isLoggedIn} url={this.props.fetchInitialDataURL} projects={this.props.projects} cmd={cmd} key={cmd.name}/>))
          }</div>
      </div>
  }
}

export default MainCommandPage