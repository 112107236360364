import React from 'react'
import ReactDOM from 'react-dom'


function LoadingSpinner(){
  return(
    <div className='loading'>
      <i className="fa fa-spinner fa-spin"></i>
      Loading...
    </div>
    )
}

export default LoadingSpinner