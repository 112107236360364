
import Pickr from '@simonwep/pickr';

window.mes_app = window.mes_app or {}
((roles, $) ->

  roles.load_colorpicker = ->
        
    pickr = Pickr.create(
        el: '.color-picker'
        theme: 'classic'
        default: $("#role_color").val() || "#58575A"

        swatches: [
            'rgb(244, 67, 54)'
            'rgb(233, 30, 99)'
            'rgb(156, 39, 176)'
            'rgb(103, 58, 183)'
            'rgb(63, 81, 181)'
            'rgb(33, 150, 243)'
            'rgb(3, 169, 244)'
            'rgb(0, 188, 212)'
            'rgb(0, 150, 136)'
            'rgb(76, 175, 80)'
            'rgb(139, 195, 74)'
            'rgb(205, 220, 57)'
            'rgb(255, 235, 59)'
            'rgb(255, 193, 7)'
        ]
        components:
            preview: true
            hue: true

            interaction:
                hex: true
                rgba: true
            
                input: true
                clear: true
                save: true
    )

    pickr.on 'save', (color,instance) => 
        rgbaColor = color.toHEXA().toString()
        $("#role_color").val(rgbaColor) 
        pickr.hide()


) window.mes_app.roles = window.mes_app.roles or {}, jQuery