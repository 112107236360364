import React from 'react'
import ReactDOM from 'react-dom'
import CommandResult from './CommandResult.jsx'
import LoadingSpinner from './LoadingSpinner.jsx'

class CommandForm extends React.Component {
  constructor(props) {
    super(props);
    this.input=React.createRef()
    this.state = {
      isLoading: false,
      text:null,
      guardians:null,
      backups:null,
      issues:null,
      error: null
    };

    this.handleExternalInputChange = this.handleExternalInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleExternalInputChange(value) {
    this.input.current.value=value
  }

  handleSubmit(event){
    event.preventDefault();
    this.setState(
      {isLoading: true},
      () =>{
        fetch(this.props.url+'/run_command?command='+encodeURIComponent(this.input.current.value))
          .then(response =>{
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json();
          })
          .then(
            (result) => {
              this.setState({
                isLoading: false,
                text: result.text,
                guardians: result.guardians ? result.guardians : null,
                backups: result.backups ? result.backups : null,
                issues: result.issues ? result.issues : null
              });
            })
          .catch((error) => {
              this.setState({
                isLoading: false,
                text: error.message,
                guardians: null,
                backups: null,
                issues: null
              });
            }
          )
      }
    )
  }

  render(){
    return (
      <div className='command-form'>
        <form onSubmit={this.handleSubmit}>
          <div className='form-group form-row'>
            <div className='cmd-label-wrapper'><label className='cmd-label'>Command:</label></div>
            <div className='col-md-6'><input className="form-control" type="text" defaultValue='' ref={this.input}/></div>
            <button className="btn btn-primary btn-fab-mini btn-fab btn-round" type="submit">
              <i className='material-icons'>play_arrow</i>
            </button>
          </div>
        </form>
        {
          !this.state.isLoading ?
            <CommandResult text={this.state.text} guardians={this.state.guardians} backups={this.state.backups} issues={this.state.issues}/>
            :
            <LoadingSpinner />
        }
      </div>
    )
  }
}

export default CommandForm