// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui")
require("bootstrap")
require("bootstrap-select")

require("application/schedules.js.coffee")
require("application/projects.js.coffee")
require("application/guardians.js.coffee")
require("application/navbar.js.coffee")
require("application/roles.js.coffee")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import CommandResult from '../src/main_command_page/components/CommandResult.jsx'
import CommandForm from '../src/main_command_page/components/CommandForm.jsx'
import Command from '../src/main_command_page/components/Command.jsx'
import MainCommandPage from '../src/main_command_page/components/MainCommandPage.jsx'
import WebpackerReact from 'webpacker-react'

// One of the following themes
require("@simonwep/pickr/dist/themes/classic.min.css")   // 'classic' theme
require("@simonwep/pickr/dist/themes/monolith.min.css") // 'monolith' theme
require("@simonwep/pickr/dist/themes/nano.min.css")     // 'nano' theme
// Modern or es5 bundle (pay attention to the note below!)


WebpackerReact.setup({
  Command,
  MainCommandPage,
  CommandForm,
  CommandResult
})