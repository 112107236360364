window.mes_app = window.mes_app or {}
((projects, $) ->
  projects.load_datepicker = ->
    if($(window).width()>=1024)
      $(".date-picker").datepicker({
        firstDay: 1,
        dateFormat: 'yy-mm-dd'
      })
    else
      $(".date-picker").prop('type','date')

  projects.showProjects = (status) ->
    x = document.getElementById(status + "Collapse")
    if x.style.display is "none" or x.style.display is ""
      x.style.display = "block"
      document.cookie = "project_#{status}_status=block"
    else
      x.style.display = "none"
      document.cookie = "project_#{status}_status=none"

  projects.getCookie = (name) ->
    value = "; " + document.cookie
    parts = value.split("; " + name + "=")
    if parts.length is 2
      parts.pop().split(";").shift()

  projects.applyStyleFromCookie = (status) ->
    x = document.getElementById(status + "Collapse")
    cookieName = "project_#{status}_status"
    cookieValue = projects.getCookie(cookieName)
    if cookieValue is "block"
      x.style.display = "block"
    else
      x.style.display = "none"

) window.mes_app.projects = window.mes_app.projects or {}, jQuery
