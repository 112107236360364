import React from 'react'
import ReactDOM from 'react-dom'

function CommandResult(props){
    return (
      <div>
        {props.text ?
          <div className='response-wrapper'>
          <span>{props.text}</span>
          <ul>
            {
              props.issues &&
                props.issues.map((issue)=>(<li key={issue.text}><i className='fa fa-chain'></i><a target='blank' href={issue.link}>{issue.text}</a></li>))
            }
            {
              props.guardians && !props.backups &&
                props.guardians.map((g)=><li key={g}>{g}</li>)
            }
            {
              props.guardians && props.backups &&
                props.guardians.map((guardians)=>
                  <li key={guardians.text}>
                    {guardians.text}
                    <ul>
                      {guardians.guardians.map(g => <li key={g}>{g}</li>)}
                    </ul></li>)
            }
            {
              props.guardians && props.backups &&
                props.backups.map((guardians)=>
                  <li key={guardians.text}>
                    {guardians.text}
                    <ul>
                      {guardians.guardians.map(g => <li key={g}>{g}</li>)}
                    </ul></li>)
            }
          </ul>
          </div>
          : null
        }
      </div>
    )
}

export default CommandResult