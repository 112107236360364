import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import CommandForm from './CommandForm.jsx'

function Command(props) {
  const [selectedProject, setSelectedProject] = useState('1')
  const form=React.createRef()

  const copyCommand=(event)=>{
    const index= event.target.getAttribute('index')
    const tooltip = event.target.parentNode.firstChild
    const textField = document.createElement('textarea')
    textField.innerText = props.cmd.options[index].command + ' ' + selectedProject
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    tooltip.innerText='Command copied'
    setTimeout((tooltip)=>tooltip.innerText='Copy command',2500,tooltip);
  }

  const fillCommandForm=(event)=>{
    form.current.handleExternalInputChange(event.target.innerText)
  }


  return <div className='row'>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-header card-header-primary'>
            <h3 className='card-title'>{props.cmd.name} Commands</h3>
            <h4>{props.cmd.description}</h4>
          </div>
          <div className='card-body'>
            <div className='panel'>
              <div className='form-floating mb-2'>
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Default select example"
                  value={selectedProject}
                  onChange={(event) => setSelectedProject(event.target.value)}
                >
                 {props.cmd.projects
                  .sort((a, b) => a.id - b.id)
                  .map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </select>
                <label className="form-label" htmlFor="floatingSelect">Please choose a project</label>
              </div>
            </div>
            {props.cmd.options.map((cmd,index)=>(
              <div key={props.cmd.name+"-option-"+index} className='panel'>
                <div className='panel-title'>
                  <div>{cmd.text}</div>
                  <code onClick={fillCommandForm}>{`${cmd.command} ${selectedProject}`}</code>
                  <span className='copy-icon-wrapper'>
                    <span className="tooltiptext">Copy command</span>
                    <i className='copy-icon material-icons' index={index} onClick={copyCommand}>
                      content_copy
                    </i>
                  </span>
                </div>
              </div>
            ))}
            {props.isLoggedIn ? <CommandForm ref={form} url={props.url}/> : null}
          </div>
        </div>
    </div>
    </div>
}

export default Command